.height{
   max-height: 100vh;
   display: block;
}
.color-delta{
    color: #00D3E5;
    font-weight: bold;
}
p strong{
    color: #00D3E5;
}
.carousel{
    position: relative;
}

.caption-carousel{
    padding-bottom: 30%;
    padding-left: 30rem;
}

span.carousel-control-prev-icon{
    display: block;
}
span.carousel-control-next-icon{
    display: block;
}

.center{
    margin-bottom: 13rem;
    font-size: 3rem;
    margin-left: 35%;
}

@media(max-width: 1900px){
    .center{
        margin-bottom: 13rem;
        font-size: 3rem;
        margin-left: 35%;
    }
}

@media(max-width: 1800px){
    .center{
        margin-bottom: 13rem;
        font-size: 3rem;
        margin-left: 35%;
    }
}

@media(max-width: 1680px){
    .center{
        margin-bottom: 12rem;
        font-size: 3rem;
        margin-left: 34%;
    }
}

@media(max-width: 1180px){
    .center{
        margin-bottom: 10rem;
        font-size: 2rem;
        margin-left: 33%;
    }
}

@media(max-width: 990px){
    #showCarousel{
        display: none;
    }
    
}

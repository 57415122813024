.containerhistoria{
    background-color: var(--light-blue);
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 50px;
    position: relative;
}

.itemhistoria{
    margin: 0;
    justify-content: center;
}

.containerhistoria img{
    background-color: var(--light-blue);
    margin-left: 10%;
}

.containerhistoria p{
    background-color: var(--light-blue);
}

.texto{
    z-index: 4;
    color: white;
    font-size: 0.9rem;
    text-align: justify;
    margin-left: 50px;
    margin-right: 50px;
}

.btnsaibamais{
    background-color: #091C8E;
    margin-left: 50px;
}
.sub{
    margin: 0;
    padding: 0;
    font-size: 2.5rem;
    font-weight: bold;
    color: #091C8E;
}
.tit{
    margin: 0;
    padding: 0;
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    line-height: 0.7;
}
.mgtop{
    margin-top: 50px;
}

.deschistoria{
    text-align: left;
}
@media(max-width: 990px){
    .mgtop{
        margin-top: 0px;
    }
    .containerhistoria{
        grid-template-columns: 1fr;
    }

    .containerhistoria img{
        width: 600px;
        margin-left: 0;
    }

    .containerhistoria P{
        background-color: var(--light-blue);
        margin: 0;
    }

    .itemhistoria{
        padding: auto;
        margin: 0;
    }

    .texto{
        color: white;
        font-size: 1rem;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }

    .btnsaibamais{
        background-color: #091C8E;
        margin-left: 200px;
        margin-top: 30px;
    }
    
}

@media (max-width: 762px){
    .deschistoria{
        text-align: center;
    }

    .btnsaibamais{
        margin-left: 0px;
        margin-top: 30px;
    }
}
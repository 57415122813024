.container-noticias{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.blue_delta{
    color: #091C8E;
    text-transform: uppercase;
}

.font-size{
    font-weight: bold;
    font-size: 1.5rem;
}

.lightblue{
    color: var(--light-blue);
}

.textnews{
    max-width: 251px;
    max-height: 270px;
    margin-left: 5px;
    text-align: left;
}

.titulo{
    color: #091C8E;
    font-weight: bold;
}

.subtitle{
    margin-top: 2rem;
    color: var(--light-blue);
}

.btnSaibaMais{
    background-color: #091C8E;
}

.btn-saiba-mais-blog{
    margin-top: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #15288f;
    color:#fff;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    font-size: 0.9rem;
    font-family: 'Poppins',sans-serif;
}

.btn-saiba-mais-blog:hover{
    background-color: #12c9e9;
}


@media(max-width: 990px){
    .preview{
        display: none;
    }
}
.alinhaimagembanner {
    text-align: right;
}

.tamanhoimg{
    width: 80%;
}

.gallery-custom {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-bottom: 26rem;
    padding-top: 5rem;
}

.gallery-size {
    max-height: 680px;
}

.about-before-galery {
    padding-top: 10rem;
}



@media(max-width: 999px){

    .alinhaimagembanner {
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .tamanhoimg{
        width: 60%;
    }

    .about-before-galery {
        padding-top: 35rem;
    }
    
}

@media (max-width: 762px) {
    .gallery-size {
        max-height: 200px;
    }

    .gallery-custom {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
}
.responsabilidade{
    background-color: #01e165;
    min-height: 40vh;
}

.titulo{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 3rem;
}

.h3as{
    color: white;
    font-size: 3.5rem;
    font-weight: 800;
}

.middleas{
    display: flex;
    justify-content: center;
    text-align: center;
}

.description{
    text-align: left;
}


@media (max-width: 762px){
    .h3as{
        color: white;
        font-size: 1.5rem;
        font-weight: 500;
    }

   
}
.containerrodape{
    background-color: #091C8E;
}

.links{
    color: white;
    list-style: none;
    font-size: 18px;
}


.sublinks{
    color: white;
    list-style: none;
    font-size: 15px;
}

.separador{
    margin-top: 30px;
    background-color: #0a21a0;
    height: 0.06rem;
}

.input{
    background-color: #091C8E;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
}

.logodelta{
    width: 200px;
    margin: 50px 50px 0px 50px;
}

.contatorodape{
    color: white;
     position: relative;

}


.bannercrop {
    position: relative;
    background: rgb(0, 0, 0);
    background-position: center;
    width: 100%;
    height: 600px;
    background-size: cover;
  }
  .bannerMob{
      display: none;
  }
@media(max-width: 990px){
    .bannerMob{
        display: block;
    }   
}

.bannercropServ {
    position: relative;
    background: rgb(0, 0, 0);
    background-position: center;
    width: 100%;
    height: 600px;
    background-size: cover;
  }

@media(max-width: 990px){
    .bannerMobServ{
        display: block;
    }   

    .bannercropServ {
        position: relative;
        background: rgb(0, 0, 0);
        background-position: center;
        width: 100%;
        height: 250px;
        background-size: cover;
      }
}

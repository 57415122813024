.font_card_localizador{
    color: white;
    text-align: left;
    font-size: 1.5rem;
    font-family: 'Poppins';
    padding-top: 2rem;
}

.select-estado{
    padding: 1px;
}

.img-loc-mob{
    display: none;
}

@media(max-width: 990px){
    .localizamob{
        display: none;
    }
    .contmob{
        padding: 10px;
    }

    .font_card_localizador{
        text-align: center;
    }

    .select-estado{
        padding: 1rem;
    }
}
@media (max-width: 762px){
    .img-loc-mob{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin-left: 40%;
    }
}
.fundo{
    background-image: linear-gradient(to left, #F2F3F5 63%, #00D3E5 50%);
}

.valor{
    color: #00D3E5;
    font-size: 2.5rem;
    font-weight: bold;
}

@media(max-width: 990px){
    .valor{
        color: #091C8E;
        font-size: 2.3rem;
        font-weight: bold;
    }
}


@media(max-width: 500px){
    .fundo{
        background-image: linear-gradient(to left, #F2F3F5 50%, #f3f3f3 50%);
    }
    
}




.bg-delta{
  background-color: #00D3E5;
}
.bottomborder{
  border-bottom: 0.5px solid rgb(255, 255, 255);
}
.nav-link{
  color: #fff !important;
  font-family: "Poppins",sans-serif !important;
}
.btn-circle {
  height: 40px;
  line-height: 40px;  
  width: 40px;  
  border-radius: 50%;  
  color: white;
  text-align: center;
  cursor: pointer;
}
.btn-circle.pt{
  background-color: #52D84B;
}
.btn-circle.en{
  background-color: #091c8e;
}

@media (min-width: 900px) {
  .cNav{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 20px;
  }  
  .cNav.nav-options {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 20px;
    list-style-type: none;
  }
}
.servcrop {
    position: relative;
    background: rgb(0, 0, 0);
    background-position: center;
    width: 300px;
    height: 90px;
    background-size: cover;
  }
.vert{
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 1rem;
}
.light-blue{
    color: var(--light-blue);
    text-transform: uppercase;
    font-size: 20px;
}
.serv-container{
    width: 60%;
}
@media(max-width: 990px){
    .serv-container{
        width: 100%;
    }   
}

@media (max-width: 762px){
    .vert{
        text-align: left;
        justify-content: left;
    }
    .blue_delta{
        margin: 10px;
    }
}
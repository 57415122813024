.hovering {
    position: relative;
    background: rgb(0, 0, 0);
    background-position: center;
    width: 100%vh;
    height: 60vh;
    max-height: 431px;
    background-size: cover;
  }
  .image {
    display: block;
    width: 100vh;
    height: auto;
    opacity: 0.9;
  }
  
  .overlay {
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.4); 
    color: #f1f1f1; 
    height: 100%;
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;
  }
  
  .hovering:hover .overlay {
    opacity: 1;
  }
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .vertical {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .horizontal {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .textpromo{      
      font-size: 10ch;
      font-weight: 500;
      font-family:  "Poppins";
      margin: 0ch;
  }

  .heading-block{
    
  }

@media(max-width: 990px){
  .hovering {
    height: 100px;
  }
  .promo{
    display: none;
  }
}
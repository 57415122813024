.lined{
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 55%;
}
@media only screen and (min-width: 1200px) {
    .lined{
        width: 45%;
    }
}